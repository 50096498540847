import React, { useEffect } from "react";

import { FaArrowRight, FaRegClock } from "react-icons/fa6";
import { Link } from "react-router-dom";
import FirstSection from "./FirstSection";
import SecondSection from "./SecondSection";
import ThirdSection from "./ThirdSection";
import FourthSection from "./FourthSection";
import FifthSection from "./FifthSection";
import SixthSection from "./SixthSection";
import SeventhSection from "./SeventhSection";
import EigthSection from "./EigthSection";
import NinthSection from "./NinthSection";

const Home = () => {

  return (
    <div id="main" className="mt-0 mb-0 z-20 relative">
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <SixthSection />
      <SeventhSection />
      <EigthSection />
      <NinthSection />
    </div>
  );
};

export default Home;
