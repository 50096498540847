import React from 'react'

const SixthSection = () => {
  return (
    <div className='mt-12 container mx-auto pb-12 border-b-2 border-blue-secondary'>
      
      <div className='space-y-5 lg:space-y-0 gap-10 lg:flex items-stretch justify-start'>
        <a
        href="https://kaffeinabucket.s3.eu-west-3.amazonaws.com/SMM/SMM-Carta-dei-Servizi+2024+WEB.pdf"
        className='mx-auto w-[300px] lg:w-[20%] block'
        >
        <img 
        data-aos="fade-right"
        src="/images/download/1.jpg"
        className='w-full'
        />

        </a>
        <div data-aos="fade-up" className='mx-auto aspect-square w-[300px] lg:w-[20%] bg-no-repeat bg-cover bg-center' style={{
            backgroundImage: "url(/images/download/2.jpg)"
        }}>

        </div>
        <div data-aos="fade-left" className='mx-auto h-[300px] lg:h-auto flex-grow bg-no-repeat bg-cover bg-center' style={{
            backgroundImage: "url(/images/download/3.jpg)"
        }}>

        </div>
      </div>

    </div>
  )
}

export default SixthSection