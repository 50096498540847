import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// Import Route Pages
import Home from "../pages/Home";
import HomeBackup from "../pages/Home/backup";
import AmministrazioneTrasparente from "../pages/AmministrazioneTrasparente";

import PrivacyPolicy from "../pages/PrivacyPolicy";
import CookiePolicy from "../pages/CookiePolicy";
import { AMMINISTRAZIONE_TRASPARENTE_URL } from "../constants";

const BaseRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      {/* Main Route */}
      <Route path="/" element={<Home />} />
      <Route path={AMMINISTRAZIONE_TRASPARENTE_URL} element={<AmministrazioneTrasparente />} />

      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default BaseRoutes;
