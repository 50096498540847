import React, { useEffect, useState } from 'react'
import { AMMINISTRAZIONE_TRASPARENTE_URL } from '../../constants'
import { useLocation } from 'react-router-dom';

const Header = () => {
  const [newImage,setNewImage] = useState(false)
  const location = useLocation();
  console.log("")


  useEffect(() => {
    console.log("location",location)
  }, [location]);




    return (

        <div className="flex w-full">
          <img
            loading="lazy"
            src={location.pathname ===AMMINISTRAZIONE_TRASPARENTE_URL || location.pathname==="/privacy-policy" || location.pathname==="/cookie-policy" ? "/images/header2.jpg" :"/images/new-header.jpg"}
            className="w-full"
          />
          {/* Header Logo */}
        <div className="absolute top-0 left-8 xl:left-24 z-20">
          <img
            loading="lazy"
            src="/images/logo.png"
            className="w-1/3 lg:w-1/2 xl:w-4/6 2xl:w-11/12"
          />
        </div>{/* <!-- Hero Image --> */}
        </div>
    )
}

export default Header