import React from 'react'

const SeventhSection = () => {
    return (
        <div className='mt-12 container mx-auto pb-12'>

            <div className='gap-10 md:flex items-center justify-start'>
                <img
                    data-aos="fade-right"
                    src="/images/icons/coffee.png"
                    className='w-[70px] lg:w-[110px]'
                />
                <div>
                    <p data-aos="fade-up" className="text-left text-blue-primary font-black text-2xl lg:text-3xl">
                        SERVIZI ACCESSORI FORNITI
                    </p>
                    <p data-aos="fade-up" className="text-left text-black text-xl lg:text-2xl">
                        All’interno del Poliambulatorio di Strada Basse del Lingotto 19/a è presente,
                        un <span className='font-bold'>BAR RISTORO</span> aperto al pubblico dal lunedì al venerdì dalle 07.00 alle 15.30.
                    </p>
                </div>

            </div>

        </div>
    )
}

export default SeventhSection