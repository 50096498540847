import React from 'react'

const EigthSection = () => {

    const dataLeft= [
        {
            icon: "/images/icon-container/10.png",
            title: "Andrologia / Urologia:",
            text: [
                "Visita Specialistica - Flussometria"
            ]
        },
        {
            icon: "/images/icon-container/14.png",
            title: "Angiologia Medica - Chirurgia Vascolare:",
            text: [
            "Visita Specialistica - Ecocolordoppler Vascolari"
            ]
        },
        {
            icon: "/images/icon-container/02.png",
            title: "Cardiologia:",
            text: [
            "Visita Specialistica",
            "Ecocardiogramma",
            "Elettrocardiogramma sotto sforzo cicloergometro",
            "Elettrocardiogramma dinamico Holter",
            "Holter pressorio",
            ]
        },
        {
            icon: "/images/icon-container/08.png",
            title: "Dermatologia:",
            text: [
            "Visita Specialistica - Controllo nei",
            ]
        },
        {
            icon: "/images/icon-container/20.png",
            title: "Dietologia / Nutrizione Clinica:",
            text: [
            "Test intolleranze alimentari",
            "Visita Dietologica - Visite Nutrizionistiche",
            ]
        },
        {
            icon: "/images/icon-container/17.png",
            title: "Endocrinologia:",
            text: [
                "Visita Specialistica"
            ]
        },
        {
            icon: "/images/icon-container/21.png",
            title: "Foniatria:",
            text: [
                "Visita Specialistica"
            ]
        },
        {
            icon: "/images/icon-container/22.png",
            title: "Gastroenterologia:",
            text: [
                "Visita Specialistica",
                "Gastroscopia",
                "Rettocolonscopia",
            ]
        },
        {
            icon: "/images/icon-container/23.png",
            title: "Laboratorio Ananlisi:",
            text: [
                "Prelievi del sangue in ambulatorio/domicilio",
                "Consegna campioni ",
                "Disbiosi Test",
                "Stress Ossidativo"
            ]
        },
        {
            icon: "/images/icon-container/12.png",
            title: "Ostetricia-Ginecologia:",
            text: [
                "Visita Specialistica",
                "Ecografia ostetrica",
                "Ecografia ginecologica (addominale e/o transvaginale)",
                "Ecografia mammaria",
                "Colposcopia - PAP Test - Batteriologico Vaginale",
                "HPV Test"
            ]
        },
        {
            icon: "/images/icon-container/18.png",
            title: "Medicina del Lavoro:",
            text: [
                "Visita Specialistica",
            ]
        },
        {
            icon: "/images/icon-container/24.png",
            title: "Medicina dello Sport:",
            text: [
                "Idoneità sportiva agonistica e non agonistica",
            ]
        },
        {
            icon: "/images/icon-container/06.png",
            title: "Neurologia:",
            text: [
                "Visita Specialistica",
                "Elettromiografie",
                "Polisonnografia",
            ]
        },
        {
            icon: "/images/icon-container/21.png",
            title: "Neuropsicomotricità Logopedia:",
            text: [
                "Attività di collaborazione con la Scuola",
                "Counselling di restituzione all’utente o alle persone che si occupano dell’utente a termine trattamento",
                "Counselling di restituzione dei dati emersi dalla valutazione",
                "Counselling con persone che si occupano dell’utente",
                "Counselling anamnestici con i genitori dell’utente",
                "Attività di rieducazione individuale per le disabilità neuropsicomotorie e neuropsicologiche in età evolutiva",
                "Valutazione delle funzioni corticali superiori correlate a disturbi motori nell’ambito di problematiche"
            ]
        },
        {
            icon: "/images/icon-container/05.png",
            title: "Oculistica:",
            text: [
                "Visita Specialistica",
                "Fundus oculi",
                "Tonometria",
                "Valutazione Ortottica",
                "Test di Schirmer",
                "Campo Visivo Computerizzato",
            ]
        },
    ]
    const dataRight = [
        
        {
            icon: "/images/icon-container/19.png",
            title: "Odontoiatria:",
            text: [
                "Conservativa - Endodonzia",
                "Protesi fissa e mobile - Ortodonzia",
                "Implantologia - Paradontologia",
                "Chirurgia Orale",
                "Igienista"
            ]
        },
        
        {
            icon: "/images/icon-container/04.png",
            title: "Ortopedia e Traumatologia:",
            text: [
            "Visita Specialistica",
            "Infiltrazioni endo articolari",
            " Onde d’urto",
            ]
        },
        {
            icon: "/images/icon-container/13.png",
            title: "Otorinolaringoiatria:",
            text: [
            "Visita Specialistica",
            " Audiometria, laringoscopia a fibre ottiche, esame vestibolare",
            ]
        },
        {
            icon: "/images/icon-container/07.png",
            title: "Pneumologia:",
            text: [
            "Visita Specialistica",
            "Spirometria",
            ]
        },
        {
            icon: "/images/icon-container/30.png",
            title: "Proctologia/Chirurgia:",
            text: [
            "Visita Specialistica",
            "Spirometria",
            ]
        },
        {
            icon: "/images/icon-container/29.png",
            title: "Psicologia:",
            text: [
            "Sostegno psicologico",
            "Psicologia",
            "Valutazione delle funzioni corticali superiori correlate a disturbi motori nell’ambito di problematiche",
            "Valutazioni Psicologiche cognitive funzioni corticali",
            "Valutazione Psicologiche funzionali corticali correlate al disturbo comunicativo",
            "Rieducazioni Psicologiche funzionali corticali correlate al disturbo comunicativo",
            "Trattamenti Psicologici",
            ]
        },
        {
            icon: "/images/icon-container/28.png",
            title: "Polisonnografia:",
            text: [
            "Ambulatorio /domicilio",
            ]
        },
        {
            icon: "/images/icon-container/27.png",
            title: "Radiologia:",
            text: [
            "Ecografia addome/ articolari/ parti molli",
            "Risonanza Magnetica (RM)",
            "Tomografia computerizzata (TC)",
            "Radiodiagnostica tradizionale",
            ]
        },
        {
            icon: "/images/icon-container/26.png",
            title: "Recupero e rieducazione funzionale:",
            text: [
            "Isocinetica",
            "Ozonoterapia",
            "Tecarterapia",
            "Pressoterapia",
            "Laserterapia",
            "Elettrostimolazioni",
            "Ultrasuoni",
            "Magnetoterapia",
            "Interferenziale",
            "Diadinamica",
            "Ionoforesi",
            "Tens",
            "Manipolazione vertebrale",
            "Rieducazione di gruppo",
            "Rieducazione di gruppo per scoliosi",
            "Linfodrenaggio",
            "Massoterapia Connettivale",
            "Massoterapia",
            "Rieducazione maggiore disabilità",
            "Rieducazione minore disabilità"
            ]
        },
        {
            icon: "/images/icon-container/25.png",
            title: "Servizio a domicilio",
            otherText: [
                "Per chi ha premura o difficoltà a muoversi, è ora disponibile un Servizio Privato di Cardiologia.",
                "SARÀ POSSIBILE ESEGUIRE AL PROPRIO DOMICILIO"
            ],
            text: [
            "Televisite",
            "Elettrocardiogramma (ECG)",
            "ECG + video-consulto online",
            "Holter cardiaco",
            "Ecocardiogramma",
            "Visita cardiologica con ECG"
            ]
        },
    ]
    return (
        <div className='mt-12  bg-[#8bceda] py-12'>
            
        <div className='container mx-auto'>
            <p data-aos="fade-right" className="text-left text-white font-medium text-2xl lg:text-3xl">
            ELENCO <span className='font-bold'>PRESTAZIONI SPECIALISTICHE</span>
            </p>

            <div className='mt-12 grid lg:grid-cols-2 content-start gap-5'>
                <div className='space-y-5'>
                    {
                        dataLeft.map((item, index) => (
                            <IconContainer key={index} item={item}/>
                        ))
                    }
                </div>
                
                <div className='space-y-5'>
                    {
                        dataRight.map((item, index) => (
                            <IconContainer key={index} item={item}/>
                        ))
                    }
                </div>

            </div>
            
        </div>
        </div>

    )
}

const IconContainer = ({item}) => {
    return (
        <div className='space-x-3 flex items-start'>
                            <img data-aos="fade-right" src={item.icon} className='flex-shrink-0 w-[50px]'/>
                            <div className=''>
                                <p data-aos="fade-right" className='text-xl font-bold text-[#004280]'>
                                    {item.title}
                                </p>
                                {
                                    item.otherText && item.otherText.map((o, oi) => (
                                        <p data-aos="fade-right" key={oi} className="font-medium text-lg" >
                                            {o}
                                        </p>
                                    ))
                                }
                                <div>
                                    {
                                        item.text.map((t, i) => (
                                            <p data-aos="fade-right" className="font-medium text-lg" key={i}>
                                                - {t}
                                            </p>
                                        ))
                                    }
                                </div>

                            </div>
                                
                        </div>
    )
}

export default EigthSection