import React from 'react'
import { IoMailOutline } from "react-icons/io5";

const ThirdSection = () => {
    const data = [
        {
            title: "POLIAMBULATORIO",
            add: "Strada Basse del Lingotto 19/A",
            phone: "011.619.5511",
            email: "prenotazioni@studiomirafiori.it"
        },
        {
            title: "POLIAMBULATORIO",
            add: "Corso Peschiera 180",
            phone: "011.012.4100",
            email: "sanpaolo@studiomirafiori.it"
        },
        {
            title: "FISIOKINESITERAPIA",
            add: "Via Passo Buole 167",
            phone: "011.617.7911",
            email: "fisioterapia@studiomirafiori.it"
        },
    ]
    return (

        <div className='mt-12 container mx-auto'>
            <div className='grid grid-cols-1 lg:grid-cols-3 gap-5'>
                {
                    data.map((item, index) => (
                        <div key={index}>
                            <p data-aos="fade-up" className='text-2xl lg:text-3xl font-black text-blue-primary'>
                                {item.title}
                            </p>
                            <p data-aos="fade-up" className='mb-3 text-xl lg:text-2xl underline text-blue-primary'>
                                {item.add}
                            </p>
                            <a data-aos="fade-up" href={`tel:${item.phone}`} className="mb-3 w-[90%] xl:w-[75%] flex items-center justify-start space-x-4 px-3 py-4 rounded-lg border-[4px] border-green-primary overflow-hidden cursor-pointer">
                                <img loading="lazy" src="/images/icons/phone.png" alt="phone-icon" className="w-[40px] xl:w-[70px]" />
                                <p className="no-underline text-green-primary text-2xl lg:text-3xl font-black">
                                    {item.phone}
                                </p>
                            </a>
                            <a className="block" data-aos="fade-up" href={`mailto:${item.email}`}>
                            <p>
                                <IoMailOutline className='text-5xl text-[#00aeef]'/>
                            </p>
                            <p className='mb-3 text-xl lg:text-2xl italic text-blue-primary'>
                                {item.email}
                            </p>
                            </a>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ThirdSection