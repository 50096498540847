import React from 'react'
import { Link } from 'react-router-dom'
import { AMMINISTRAZIONE_TRASPARENTE_URL } from '../../constants'

const Footer = () => {
    return (
        <div id="footer" className='relative flex-shrink-0 bg-gray-bg'>
            {/* <footer className='absolute bottom-0 z-[-1] flex-shrink-0'> */}
            <div className="container mx-auto flex flex-col items-center justify-between px-4 lg:px-6 mb-0">
                <div className='flex items-center justify-center py-8'>
                    <div className="grid grid-cols-1 md:grid-cols-5 gap-12">
                        <div className="flex items-start gap-x-4">
                            <div className="w-full">
                                <Link to={"/"} className="">
                                    <img src="/images/logo.png" alt="" className="w-full" />
                                </Link>
                            </div>
                        </div>

                        <div className="md:col-span-3 w-full flex flex-col md:flex-row lg:flex-col space-x-0 md:space-x-8 lg:space-x-0 items-start text-blue-primary">
                            <div className='flex flex-col items-start mb-4'>
                                <p className='mb-0 text-sm font-bold'>STUDIO MEDICO MIRAFIORI SRL</p>
                                <p className='mb-0 text-sm font-normal'>Piazza Gozzano 1 Torino 10132</p>
                                <p className='mb-2 text-sm font-normal'>P.I. 01133860013</p>
                                <p className='mb-0 text-sm font-normal'>Azienda Accreditata alla Regione Piemonte con il DGR n. 40-14911 del 28/02/2005</p>
                                <p className='mb-1 text-sm font-normal'>Azienda con Sistema di Gestione Qualità certificato ISO 9001:2015 per le sedi Di Strada Basse del Lingotto 19/A e Via Passo Buole 167</p>
                                <p className='mb-0 text-sm font-normal'><span className='font-bold'>Direttore Sanitario:</span> Dott. Alessandro Alberti</p>
                            </div>

                            <div className='flex flex-wrap'>
                                {/* <p className='mb-0 text-sm font-normal'>struttura accreditata con il servizio sanitario nazionale - Villa IRIS II</p> */}
                                <div className='flex flex-wrap gap-x-2 font-bold'>
                                    <Link to={AMMINISTRAZIONE_TRASPARENTE_URL} className='mb-0 underline hover:cursor-pointer'>AMMINISTRAZIONE TRASPARENTE</Link>
                                    <p className='mb-0'>|</p>
                                    <Link to={"/privacy-policy"} className='mb-0 underline hover:cursor-pointer'>PRIVACY POLICY</Link>
                                    <p className='mb-0'>|</p>
                                    <Link to={"/cookie-policy"} className='mb-0 underline hover:cursor-pointer'>COOKIE POLICY</Link>
                                    <p className='mb-0'>|</p>
                                    <a href={"https://kaffeinabucket.s3.eu-west-3.amazonaws.com/Politica+della+qualit%C3%A0+2024+(2).pdf"} target='_blank' className='mb-0 underline hover:cursor-pointer'>POLITICA DELLA QUALITÀ</a>
                                    {/* <Link to={"/"} className='mb-0 underline hover:cursor-pointer'>PROGETTO DI GESTIONE</Link> */}
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-row space-x-4 space-y-0 lg:space-y-3 xl:space-y-0 lg:space-x-0 lg:flex-col xl:flex-row xl:space-x-4 flex-shrink-0'>
                            <img src='/images/Logo_accredia.png' className='flex-shrink-0 w-32 h-32'/>
                            <img src='/images/Logo_kiwa.png' className='flex-shrink-0 w-32 h-32' />
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer