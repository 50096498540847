import React from 'react'
import { IoMailOutline } from "react-icons/io5";
import { RxClock } from "react-icons/rx";
import { FaArrowRight } from "react-icons/fa6";

const FourthSection = () => {
    const data = [
        {
            aos:"fade-right",
            background: "#0671ba",
            text: "#fff",
            first: {
                title: "ORARI APERTURA",
                des: "LUN / VEN",
                time1: "07.00",
                time2: "19.00"
            },
            second: {
                title: "PRENOTAZIONI",
                des: "LUN / VEN",
                time1: "10.00",
                time2: "18.30"
            },
        },
        {
            aos:"fade-up",
            background: "#8bceda",
            text: "#fff",
            first: {
                title: "ORARI APERTURA",
                des: "LUN / VEN",
                time1: "07.30",
                time2: "18.00"
            },
            second: {
                title: "PRENOTAZIONI",
                des: "LUN / VEN",
                time1: "07.30",
                time2: "18.00"
            },
        },
        {
            aos: "fade-left",
            background: "#e6e6e6",
            text: "#0671ba",
            first: {
                title: "ORARI APERTURA",
                des: "LUN / VEN",
                time1: "08.00",
                time2: "19.00"
            },
            second: {
                title: "PRENOTAZIONI",
                des: "LUN / VEN",
                time1: "08.00",
                time2: "17.00"
            },
        },

    ]
    return (

        <div className='mt-12 container mx-auto pb-12 border-b-2 border-blue-secondary'>
            <div className='grid lg:grid-cols-3 gap-5'>
                {
                    data.map((item, index) => (
                        <div data-aos={item.aos}>
                            <div
                                key={index}
                                className='p-4 lg:w-[85%] xl:w-[65%] space-y-2'
                                style={{ backgroundColor: item.background }}
                            >
                                <div>
                                    <RxClock className='text-[70px] mb-3' style={{ color: item.text }} />
                                    <p className='font-bold text-2xl' style={{ color: item.text }}>
                                        {item.first.title}
                                    </p>
                                    <p className='text-2xl font-light' style={{ color: item.text }}>
                                        {item.first.des}
                                    </p>
                                    <div className='flex items-center' style={{ color: item.text }}>
                                        <p className='font-light text-2xl' style={{ color: item.text }}>
                                            {item.first.time1}
                                        </p>
                                        <FaArrowRight className='mx-1 text-3xl' />
                                        <p className='font-light text-2xl' style={{ color: item.text }}>
                                            {item.first.time2}
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <p className='font-bold text-2xl' style={{ color: item.text }}>
                                        {item.second.title}
                                    </p>
                                    <p className='text-2xl font-light' style={{ color: item.text }}>
                                        {item.second.des}
                                    </p>
                                    <div className='flex items-center' style={{ color: item.text }}>
                                        <p className='font-light text-2xl' style={{ color: item.text }}>
                                            {item.second.time1}
                                        </p>
                                        <FaArrowRight className='mx-1 text-3xl' />
                                        <p className='font-light text-2xl' style={{ color: item.text }}>
                                            {item.second.time2}
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    ))
                }
            </div>
            <p data-aos="fade-right" className="mt-10 text-cls">
                Siamo qui per offrirvi un’eccellenza nel servizio sanitario, mettendo al centro della nostra
                attenzione la vostra salute e il vostro benessere.
            </p>
            
            <div className='mt-12'>
                <p data-aos="fade-right" className="text-left text-blue-primary font-normal underline text-2xl lg:text-3xl">
                PRENOTAZIONI <span className='font-bold'> PRIVATE</span>
                </p>
                <a data-aos="fade-right" href={"mailto:privato@studiomirafiori.it"} className="italic text-cls block">
                privato@studiomirafiori.it
                </a>
            </div>
            <div className='mt-8'>
                <p data-aos="fade-right" className="text-left text-blue-primary font-normal underline text-2xl lg:text-3xl">
                PRENOTAZIONI  <span className='font-bold'> MEDICINA DEL LAVORO</span>
                </p>
                <a data-aos="fade-right" href={"mailto:mdl@studiomirafori.it"} className="italic text-cls block">
                mdl@studiomirafori.it
                </a>
            </div>
            <div className='mt-8'>
                <p data-aos="fade-right" className="text-left text-blue-primary font-normal underline text-2xl lg:text-3xl">
                PER <span className='font-bold'> DISDETTE</span>
                </p>
                <a data-aos="fade-right" href={"mailto:disdette@studiomirafiori.it"} className="italic text-cls block">
                disdette@studiomirafiori.it
                </a>
            </div>
        </div>
    )
}

export default FourthSection