import React from 'react'

const EigthSection = () => {

    return (
        <div className='bg-[#ededed] py-12'>
            
        <div className='container mx-auto'>
            <p data-aos="fade-right" className="text-left text-black font-black text-2xl lg:text-3xl underline">
            LAVORA CON NOI
            </p>
            
            <p data-aos="fade-right" className="mt-5 text-left text-black font-medium text-xl lg:text-2xl">
            Studio Medico Mirafiori è alla continua ricerca di persone dinamiche da integrare
al proprio organico per l’attività di reparto e ambulatoriali, lavorare con Noi è anche e soprattutto
un’occasione di crescita professionale. Per proporre la candidatura inviare il CV all’indirizzo
email: direzione@studiomirafiori.it
            </p>

        </div>
        </div>

    )
}

export default EigthSection