import React from 'react'

const SecondSection = () => {
    return (
        <>

            <div className='mt-8 container mx-auto pb-12 border-b-2 border-blue-secondary'>
                <div className='space-y-3'>
                    <p data-aos="fade-right" className="text-cls">
                        Benvenuti nell’ambulatorio <span className='font-bold'>Studio Medico Mirafiori.</span>
                    </p>
                    <p data-aos="fade-right" className="text-cls">
                        Siamo qui per offrirvi un’eccellenza nel servizio sanitario, mettendo al centro della nostra
                        attenzione la vostra salute e il vostro benessere.
                    </p>
                    <p data-aos="fade-right" className="text-cls">
                        Nel nostro poliambulatorio, ci impegniamo a fornire cure mediche comprensive e personalizzate,
                        grazie a un <span className='font-bold'>team di professionisti altamente qualificati</span> e all’avanguardia nelle rispettive
                        specializzazioni
                    </p>
                    <p data-aos="fade-right" className="text-cls">
                        Siamo qui per ascoltarvi, comprendere le vostre esigenze e offrirvi soluzioni di cura su misura,
                        con un approccio empatico e attento. Attraverso l’innovazione, la tecnologia e un impegno
                        costante per l’eccellenza clinica, ci sforziamo di essere il vostro partner fidato nel percorso verso
                        una salute ottimale.
                    </p>
                </div>
            </div>








           
        </>
    )
}

export default SecondSection